import { create } from "zustand";
import axios from "axios";

const REACT_APP_AWS_API_GATEWAY = process.env.REACT_APP_AWS_API_GATEWAY;

const DEFAULT_USER = {
    id: null,
    username: "",
    bio: "",
    pfp: "/images/Default-PFP.png",
    onboarded: false
};

const store = (set) => ({
    isUserLoading: true,
    user: DEFAULT_USER,
    getUser: async () => {
        const u = (await axios.get(`${REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/get_user`)).data;
        set((state) => ({
            isUserLoading: false,
            user: {
                ...state.user,
                id: u._id,
                username: u.username,
                bio: u.bio,
                pfp: u.pfp,
                onboarded: u.onboarded
            }
        }));
    },
});

const useStore = create(store);

export default useStore;
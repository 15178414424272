import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import { Card } from "../ui/shadcn/Card";
import Loader from "../ui/Loader";

const DEFAULT_FOLLOWING_USERS = [];
const DEFAULT_PFP = "/images/Default-PFP.png";

const Following = () => {
    const [followingUsers, setFollowingUsers] = useState(DEFAULT_FOLLOWING_USERS);
    const [isFollowingLoading, setIsFollowingLoading] = useState(true); // Start loading by default
    const navigate = useNavigate();
    const { profileUsername } = useParams();

    useEffect(() => {
        if (profileUsername) {
            init();
        }
    }, [profileUsername]);

    const init = async () => {
        setIsFollowingLoading(true);
        try {
            const profileId = await getProfileId();
            if (profileId) {
                await getFollowingUsers(profileId);
            } else {
                // Handle case where profileId could not be obtained
                setFollowingUsers(DEFAULT_FOLLOWING_USERS);
            }
        } catch (err) {
            console.error(err);
            toast.error("Error initializing profile");
        } finally {
            setIsFollowingLoading(false);
        }
    };

    const getProfileId = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_id", {
                params: {
                    profile_username: profileUsername
                }
            });
            if (status === 200) {
                return data?.profile_id;
            } else {
                throw new Error("Error getting profile id");
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile id");
        }
    };

    const getFollowingUsers = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_following_users", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                setFollowingUsers(data?.users || DEFAULT_FOLLOWING_USERS);
            } else {
                throw new Error("Error getting following users");
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting following users");
        }
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header />
                <img
                    src="/images/chevron-left.svg"
                    alt="Back"
                    onClick={() => navigate(`/profile/${profileUsername}`)}
                    style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px',
                    }}
                />
                <div className="flex flex-col sm:flex-row justify-center items-center full">
                    <div className="w-auto sm:w-auto px-4 sm:px-0">
                        <div className="content text-center">
                            {isFollowingLoading ? (
                                <Loader />
                            ) : followingUsers.length > 0 ? (
                                followingUsers.map((user, i) => (
                                    <Card key={i} onClick={() => navigate(`/profile/${user?.username}`)} className="w-full mb-4 p-4 cursor-pointer">
                                        <img src={user?.pfp ? user?.pfp : DEFAULT_PFP} alt={"Default PFP"} className="w-20 h-20 object-cover object-center rounded-sm mx-auto" />
                                        <div className="flex flex-col items-center justify-center gap-2">
                                            <h2 className="text-xl">{user?.username}</h2>
                                            <p className="text-sm overflow-wrap break-words text-center">{user?.bio}</p>
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <p className="mb-4">Not following any users. A little antisocial if you ask me!</p>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Following;

import React, { useState } from "react";
import { Box, Modal, Rating } from "@mui/material";
import { Button } from "../ui/shadcn/Button";
import { Input } from "../ui/shadcn/Input";
import { TextAreaShadCN } from "../ui/shadcn/TextAreaShadCN";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import BookGrid from "./BookGridSearch";
import Loader from "../ui/Loader";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const ReviewModal = ({
    isModalOpen,
    setIsModalOpen,
    modalStep,
    setModalStep,
    googleBooksSearch,
    setGoogleBooksSearch,
    handleSearchGoogleBooks,
    isDataLoading,
    googleBooksBooks,
    selections,
    handleAddBook,
    handleRemoveBook,
    rating,
    setRating,
    content,
    setContent,
    date,
    setDate,
    handlePostReview,
    handleCloseReviewModal
}) => {

    const [isPosting, setIsPosting] = useState(false); 

    const handleChange = (setter, limit) => (e) => {
        if (e.target.value.length <= limit) {
            setter(e.target.value);
        }
    };

    const handleClick = async () => {
        if (isPosting) return;
        setIsPosting(true);
        try { 
            await handlePostReview();
        }
        finally {
            setIsPosting(false);
        }
      };

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseReviewModal}
        >
            <Box sx={style} style={{ overflowY: "auto", maxHeight: "75vh" }}>
            <img
                    src="/images/x-close.svg"
                    alt="Close"
                    onClick={handleCloseReviewModal}
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        cursor: 'pointer',
                        width: '20px',
                        height: '20px'
                    }}
                />
                <div className="flex flex-col items-center gap-4 w-full text-center">
                    {modalStep === 1 && (
                        <>
                        <div className="text-left">
                            <h2 className="text-2xl md:text-3xl mb-4" >What did you just read?</h2>
                            <p>You finished a book!!! We're proud of you!</p>
                        </div>
                            <div className="flex justify-center gap-4 w-full">
                                <form className="flex justify-between items-center" onSubmit={(e) => { e.preventDefault(); handleSearchGoogleBooks(googleBooksSearch); }}>
                                    <label>
                                        <Input type="text" placeholder="Search books, authors" value={googleBooksSearch} onChange={(e) => setGoogleBooksSearch(e.target.value)} />
                                    </label>
                                    <div className="px-4">
                                        <Button type="submit">Search</Button>
                                    </div>
                                </form>
                            </div>
                            {isDataLoading ?
                                <Loader /> :
                                googleBooksBooks.length > 0 &&
                                <BookGrid small type="select" books={googleBooksBooks} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                            }
                            {selections.length > 0 &&
                                <>
                                    <h3 className="text-center text-2xl">Selected Book</h3>
                                    <BookGrid small type="select" books={selections} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                                </>
                            }
                            {selections.length === 1 && <Button onClick={() => setModalStep(2)}>Next</Button>}
                        </>
                    )}
                    {modalStep === 2 && (
                        <>
                        <div className="text-left">
                            <h2 className="text-2xl md:text-3xl mb-4" >What would you rate it?</h2>
                            <p>There are no half stars you have to be decisive.</p>
                        </div>
                            <Rating
                                value={rating}
                                onChange={(e, rating) => {
                                    setRating(rating);
                                }}
                            />
                            <div className="flex justify-center gap-4 w-full">
                                <Button onClick={() => setModalStep(1)}>Previous</Button>
                                <Button onClick={() => setModalStep(3)}>Next</Button>
                            </div>
                        </>
                    )}
                    {modalStep === 3 && (
                        <>
                        <div className="text-left">
                            <h2 className="text-2xl md:text-3xl mb-4" >What did you think of it?</h2>
                            <p>Don’t overthink it. You’re a reader, not a writer. Hot takes encouraged.</p>
                        </div>
                            <TextAreaShadCN value={content} onChange={handleChange(setContent, 250)} />
                            <p>{content?.length}/250</p>
                            <div className="flex justify-center gap-4 w-full">
                                <Button onClick={() => setModalStep(2)}>Previous</Button>
                                <Button onClick={() => setModalStep(4)}>Next</Button>
                            </div>
                        </>
                    )}
                    {modalStep === 4 && (
                        <>
                        <div className="text-left">
                            <h2 className="text-2xl md:text-3xl mb-4" >When did you finish it?</h2>
                            <p>It's ok if you don't know the exact date.</p>
                        </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disableFuture={true}
                                    value={date}
                                    onChange={(date) => setDate(date)}
                                />
                            </LocalizationProvider>
                            <div className="flex justify-center gap-4 w-full">
                                <Button onClick={() => setModalStep(3)}>Previous</Button>
                                {date && <Button onClick={() => handleClick()} disabled={isPosting}>Post Review</Button>}
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
}

export default ReviewModal;

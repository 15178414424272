import React from "react";

const Loader = () => {
    return (
        <div className="flex-row-center-center full">
            <svg
                className="spinner"
                width="4rem"
                height="4rem"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className="path"
                    fill="none"
                    strokeWidth="6"
                    strokeLinecap="round"
                    cx="33"
                    cy="33"
                    r="30"
                ></circle>
            </svg>
        </div>
    );
};

export default Loader;
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import Collection from "../ui/Collection";
import BookGridProfile from "../ui/BookGridProfile";
import Reviews from "../ui/Reviews";
import Review from "../ui/Review";
import Readings from "../ui/Readings";
import Reading from "../ui/Reading";
import ToReads from "../ui/ToReads";
import ToRead from "../ui/ToRead";
import CollectionModal from "../ui/CollectionModal";
import ReviewModal from "../ui/ReviewModal";
import ReadingModal from "../ui/ReadingModal";
import Loader from "../ui/Loader";
import { Button } from "../ui/shadcn/Button";
import { Tabs, TabsContent, TabsListComponent, TabsTrigger } from "../ui/shadcn/Tabs";
import useStore from "../../store/store";
import { formatCollectionName } from "../utils/formatters";

const DEFAULT_PROFILE_USER = {};
const DEFAULT_PROFILE_COLLECTIONS = null;
const DEFAULT_SELECTED_COLLECTION = null;
const DEFAULT_NEW_COLLECTION_NAME = "";
const DEFAULT_SELECTIONS = [];
const DEFAULT_GOOGLE_BOOKS_SEARCH = "";
const DEFAULT_GOOGLE_BOOKS_BOOKS = [];
const DEFAULT_PROFILE_REVIEWS = null;
const DEFAULT_PROFILE_READINGS = null;
const DEFAULT_PROFILE_TO_READS = null;
const DEFAULT_SELECTED_REVIEW = null;
const DEFAULT_SELECTED_READING = null;
const DEFAULT_SELECTED_TO_READ = null;
const DEFAULT_RATING = 0;
const DEFAULT_CONTENT = "";
const DEFAULT_READ_DATE = null;
const DEFAULT_START_DATE = null;
const DEFAULT_COLLECTION_MODAL_MODE = "";
const DEFAULT_COLLECTION_MODAL_EDIT_COLLECTION = "";
const DEFAULT_COLLECTION_MODAL_STEP = 1;
const DEFAULT_IS_COLLECTION_MODAL_OPEN = false;
const DEFAULT_REVIEW_MODAL_STEP = 1;
const DEFAULT_READING_MODAL_STEP = 1;
const DEFAULT_IS_REVIEW_MODAL_OPEN = false;
const DEFAULT_IS_READING_MODAL_OPEN = false;
const DEFAULT_IS_PROFILE_LOADING = false;
const DEFAULT_IS_DATA_LOADING = false;
const DEFAULT_FOLLOWING_COUNT = 0;
const DEFAULT_FOLLOWING_USERS = [];

const DEFAULT_PFP = "/images/Default-PFP.png";
const DEFAULT_COLLECTIONS = ["Reading", "Read", "To Read", "Reviewed"];

const Profile = () => {
    const [profileUser, setProfileUser] = useState(DEFAULT_PROFILE_USER);
    const [profileCollections, setProfileCollections] = useState(DEFAULT_PROFILE_COLLECTIONS);
    const [selectedCollection, setSelectedCollection] = useState(DEFAULT_SELECTED_COLLECTION);
    const [newCollectionName, setNewCollectionName] = useState(DEFAULT_NEW_COLLECTION_NAME);
    const [selections, setSelections] = useState(DEFAULT_SELECTIONS);
    const [googleBooksSearch, setGoogleBooksSearch] = useState(DEFAULT_GOOGLE_BOOKS_SEARCH);
    const [googleBooksBooks, setGoogleBooksBooks] = useState(DEFAULT_GOOGLE_BOOKS_BOOKS);
    const [profileReviews, setProfileReviews] = useState(DEFAULT_PROFILE_REVIEWS);
    const [profileReadings, setProfileReadings] = useState(DEFAULT_PROFILE_READINGS);
    const [profileToReads, setProfileToReads] = useState(DEFAULT_PROFILE_TO_READS);
    const [selectedReview, setSelectedReview] = useState(DEFAULT_SELECTED_REVIEW);
    const [selectedReading, setSelectedReading] = useState(DEFAULT_SELECTED_READING);
    const [selectedToRead, setSelectedToRead] = useState(DEFAULT_SELECTED_TO_READ);
    const [rating, setRating] = useState(DEFAULT_RATING);
    const [content, setContent] = useState(DEFAULT_CONTENT);
    const [readDate, setReadDate] = useState(DEFAULT_READ_DATE);
    const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
    const [collectionModalMode, setCollectionModalMode] = useState(DEFAULT_COLLECTION_MODAL_MODE);
    const [collectionModalEditCollection, setCollectionModalEditCollection] = useState(DEFAULT_COLLECTION_MODAL_EDIT_COLLECTION);
    const [collectionModalStep, setCollectionModalStep] = useState(DEFAULT_COLLECTION_MODAL_STEP);
    const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(DEFAULT_IS_COLLECTION_MODAL_OPEN);
    const [reviewModalStep, setReviewModalStep] = useState(DEFAULT_REVIEW_MODAL_STEP);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(DEFAULT_IS_REVIEW_MODAL_OPEN);
    const [readingModalStep, setReadingModalStep] = useState(DEFAULT_READING_MODAL_STEP);
    const [isReadingModalOpen, setIsReadingModalOpen] = useState(DEFAULT_IS_READING_MODAL_OPEN);
    const [isProfileLoading, setIsProfileLoading] = useState(DEFAULT_IS_PROFILE_LOADING);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followingCount, setFollowingCount] = useState(DEFAULT_FOLLOWING_COUNT);
    const [followingUsers, setFollowingUsers] = useState(DEFAULT_FOLLOWING_USERS);
    const [isDataLoading, setIsDataLoading] = useState(DEFAULT_IS_DATA_LOADING);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const { profileUsername, collectionName, reviewId, readingId, toReadId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (profileUsername) {
            init();
        } else {
            navigate(`/profile/${user.username}`);
        }
    }, [profileUsername]);

    useEffect(() => {
        getProfileReadings();
        getProfileReviews();
        getProfileToReads();
    }, []);

    useEffect(() => {
        if (profileUsername && profileCollections && collectionName) {
            const formattedCollectionNames = Object.keys(profileCollections).map(name => formatCollectionName(name));
            const index = formattedCollectionNames.indexOf(collectionName);
            if (index !== -1) {
                setSelectedCollection(Object.keys(profileCollections)[index]);
            } else {
                navigate(`/profile/${profileUsername}`);
            }
        } else {
            setSelectedCollection(DEFAULT_SELECTED_COLLECTION);
        }
    }, [profileUsername, profileCollections, collectionName]);

    useEffect(() => {
        if (profileUsername && profileReviews && reviewId) {
            const selectedReview = profileReviews.find(review => review._id === reviewId);
            if (selectedReview) {
                setSelectedReview(selectedReview);
            } else {
                navigate(`/profile/${profileUsername}`);
            }
        } else {
            setSelectedReview(DEFAULT_SELECTED_REVIEW);
        }
    }, [profileUsername, profileReviews, reviewId]);

    useEffect(() => {
        if (profileUsername && profileReadings && readingId) {
            const selectedReading = profileReadings.find(reading => reading._id === readingId);
            if (selectedReading) {
                setSelectedReading(selectedReading);
            } else {
                navigate(`/profile/${profileUsername}`);
            }
        } else {
            setSelectedReading(DEFAULT_SELECTED_READING);
        }
    }, [profileUsername, profileReadings, readingId]);

    useEffect(() => {
        if (profileUsername && profileToReads && toReadId) {
            const selectedToRead = profileToReads.find(toRead => toRead._id === toReadId);
            if (selectedToRead) {
                setSelectedToRead(selectedToRead);
            } else {
                navigate(`/profile/${profileUsername}`);
            }
        } else {
            setSelectedToRead(DEFAULT_SELECTED_TO_READ);
        }
    }, [profileUsername, profileToReads, toReadId]);


    const init = async () => {
        setIsProfileLoading(true);
        try {
            const profileId = await getProfileId();
            await getProfileUser(profileId);
            await checkIfFollowing(profileId);
            await getProfileCollections(profileId);
            await getProfileReadings(profileId);
            await getProfileReviews(profileId);
            await getProfileToReads(profileId);
        } catch (err) {
            console.error(err);
            toast.error("Error initializing profile");
        } finally {
            setIsProfileLoading(DEFAULT_IS_PROFILE_LOADING);
        }
    };

    const getProfileId = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_id", {
                params: {
                    profile_username: profileUsername
                }
            });
            if (status === 200) {
                const profileId = data?.profile_id;
                return profileId;
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile id");
        }
    };

    const getProfileUser = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_user", {
                params: {
                    profile_id: profileId
                }
            });
            getFollowingCount(profileId);
            if (status === 200) {
                const user = data?.user;
                setProfileUser(user);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile user");
        }
    };

    const checkIfFollowing = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/check_if_following", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const isFollowing = data?.is_following;
                setIsFollowing(isFollowing);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error checking if following");
        }
    };

    const handleFollowProfile = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/toggle_follow_profile", {
                profile_id: profileUser?._id
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "follow") {
                    toast.success("Following profile");
                    setIsFollowing(true);
                    handleFollowProfileNotification();
                } else if (message === "unfollow") {
                    toast.success("Unfollowing profile");
                    setIsFollowing(false);
                }
            } else if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error following profile");
        }
    };

    const handleFollowProfileNotification = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/new_follower_sms", {
                profile_id: profileUser?._id
            });
        } catch (err) {
            console.error(err);
        }
    }

    const getProfileCollectionId = async (profileId, collectionName) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_collection_id", {
                params: {
                    profile_id: profileId,
                    collection_name: collectionName
                }
            });
            if (status === 200) {
                const collection_id = data?.collection_id
                return collection_id;
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile collection id");
        }
    };

    const getProfileCollections = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_collections", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const collections = data?.collections;
                setProfileCollections({ ...collections });
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile collections");
        }
    };

    const handleCollectionClick = (collectionName) => {
        navigate(`/profile/${profileUsername}/${collectionName}`);
    };

    const handleSearchGoogleBooks = async (query) => {
        setIsDataLoading(true);
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/google_books/search`, {
                params: {
                    query
                }
            });
            if (status === 200) {
                const books = data?.books;
                console.log("books is...");
                console.log(books);
                setGoogleBooksBooks(books);
                console.log("setGoogleBooksBooks is: ");
                console.log(googleBooksBooks);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        } finally {
            setIsDataLoading(DEFAULT_IS_DATA_LOADING);
        }
    };

    const handleCollectionAddBook = (book) => {
        setSelections([...selections, book]);
    };

    const handleCollectionRemoveBook = (book) => {
        setSelections(selections.filter(selection => selection !== book));
    };

    const handleCreateCollection = async () => {
        try {
            setCollectionModalMode("create");
            setIsCollectionModalOpen(true);
        } catch (err) {
            console.error(err);
            toast.error("Error creating collection");
        }
    };

    const handleCreateOrUpdateCreation = async () => {
        try {
            await processBooks();
            await getProfileCollections(profileUser._id);
        } catch (err) {
            console.error(err);
            toast.error("Error creating new collection");
        } finally {
            await handleCloseCollectionModal();
        }
    };

    const handleEditCollection = async (collectionModalEditCollection) => {
        try {
            setNewCollectionName(selectedCollection);
            setSelections(profileCollections[selectedCollection]);
            setCollectionModalMode("edit");
            setCollectionModalEditCollection(collectionModalEditCollection);
            setIsCollectionModalOpen(true);
        } catch (err) {
            console.error(err);
            toast.error("Error editing collection");
        }
    };

    const handleDeleteCollection = async () => {
        try {
            const collectionId = await getProfileCollectionId(profileUser._id, selectedCollection);
            const { data, status } = await axios.delete(`/api/e/v1/social/delete_collection/${collectionId}`);
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error deleting collection");
        } finally {
            navigate(`/profile/${profileUsername}`);
            await getProfileCollections(profileUser._id);
        }
    };

    const processBooksReadOrReading = async (collectionName) => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections,
                collection_name: collectionName
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error processing books");
        }
    };

    const processBooks = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections,
                collection_name: newCollectionName
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error processing books");
        }
    };

    const handleCloseCollectionModal = async () => {
        try {
            setIsCollectionModalOpen(DEFAULT_IS_COLLECTION_MODAL_OPEN);
            setCollectionModalMode(DEFAULT_COLLECTION_MODAL_MODE);
            setCollectionModalEditCollection(DEFAULT_COLLECTION_MODAL_EDIT_COLLECTION);
            setCollectionModalStep(DEFAULT_COLLECTION_MODAL_STEP);
            setNewCollectionName(DEFAULT_NEW_COLLECTION_NAME);
            setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
            setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
            setSelections(DEFAULT_SELECTIONS);
        } catch (err) {
            console.error(err);
            toast.error("Error closing collection modal");
        }
    };


    const getProfileReadings = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_readings", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const readings = data?.readings;
                setProfileReadings(readings);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile readings");
        }
    };

    const handleReadingClick = (readingId) => {
        navigate(`/profile/${profileUsername}/readings/${readingId}`);
    };

    const handleReadingAddBook = (book) => {
        setSelections([book]);
        setGoogleBooksBooks([]); // Clear the search results
    };

    const handleReadingRemoveBook = () => {
        setSelections(DEFAULT_SELECTIONS);
    };

    const handlePostReading = async () => {
        try {
            await processBooksReadOrReading("Reading");
            await handleCreateReading();
            await getProfileReadings(profileUser._id);
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        } finally {
            await handleCloseReadingModal();
        }
    };

    const handleCreateReading = async () => {
        try {
            console.log("start date is..");
            console.log(startDate);
            const { data, status } = await axios.post("/api/e/v1/social/create_reading", {
                isbn: selections[0].isbn,
                content,
                start_date: startDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        }
    };

    const handleCloseReadingModal = async () => {
        try {
            setIsReadingModalOpen(DEFAULT_IS_READING_MODAL_OPEN);
            setReadingModalStep(DEFAULT_READING_MODAL_STEP);
            setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
            setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
            setSelections(DEFAULT_SELECTIONS);
            setContent(DEFAULT_CONTENT);
            setStartDate(DEFAULT_START_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing reading modal");
        }
    };

    const getProfileReviews = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_reviews", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const reviews = data?.reviews;
                setProfileReviews(reviews);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile reviews");
        }
    };

    const handleReviewClick = (reviewId) => {
        navigate(`/profile/${profileUsername}/reviews/${reviewId}`);
    };

    const handleReviewAddBook = (book) => {
        setSelections([book]);
        setGoogleBooksBooks([]); // Clear the search results
    };

    const handleReviewRemoveBook = () => {
        setSelections(DEFAULT_SELECTIONS);
    };

    const handlePostReview = async () => {
        try {
            await processBooksReadOrReading("Read");
            await handleCreateReview();
            await getProfileReviews(profileUser._id);
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        } finally {
            await handleCloseReviewModal();
        }
    };

    const handleCreateReview = async () => {
        console.log("read date is..");
        console.log(readDate);
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                isbn: selections[0].isbn,
                rating,
                content,
                read_date: readDate
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const handleCloseReviewModal = async () => {
        try {
            setIsReviewModalOpen(DEFAULT_IS_REVIEW_MODAL_OPEN);
            setReviewModalStep(DEFAULT_REVIEW_MODAL_STEP);
            setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
            setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
            setSelections(DEFAULT_SELECTIONS);
            setRating(DEFAULT_RATING);
            setContent(DEFAULT_CONTENT);
            setReadDate(DEFAULT_READ_DATE);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    const getProfileToReads = async (profileId) => {
        console.log("IN getProfileToReads")
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_profile_to_reads", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const toReads = data?.toReads;
                setProfileToReads(toReads);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting profile to reads");
        }
    };

    const handleToReadClick = (toReadId) => {
        navigate(`/profile/${profileUsername}/toreads/${toReadId}`);
    };

    const getFollowingCount = async (profileId) => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_following_count", {
                params: {
                    profile_id: profileId
                }
            });
            if (status === 200) {
                const count = data?.count;
                setFollowingCount(count);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error getting following count");
        }
    };

    const handleFollowingClick = () => {
        navigate(`/profile/${profileUsername}/following`);
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header handleOpenReviewModal={() => setIsReviewModalOpen(true)} handleOpenCollectionModal={handleCreateCollection} />
                <div className="flex flex-col md:flex-row items-center justify-center w-full">
                    <div className="flex flex-col items-center justify-center w-full md:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-1/2 p-4">
                        <div className="content text-center">
                            {isUserLoading || isProfileLoading ?
                                <Loader /> :
                                <>
                                    <div className="flex-row-center-center full relative">
                                        <div className="flex flex-col lg:flex-row items-center justify-center gap-4 w-full lg:w-auto ">
                                            {user.id === profileUser?._id && (
                                                <Link to={`/settings`} className="absolute top-0 right-0">
                                                    <img src="/images/settings-01.svg" alt="settings icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                                                </Link>
                                            )}
                                            <img src={profileUser?.pfp ? profileUser?.pfp : DEFAULT_PFP} alt={profileUser?.username} className="w-40 h-40 md:w-40 md:h-40 mx-auto lg:mx-0 object-cover object-center rounded-sm" />
                                            <div className="flex flex-col items-center lg:items-center justify-center gap-2 w-full">
                                                <h2 className="text-2xl md:text-3xl">{profileUser?.username}</h2>
                                                <p className="text-sm md:text-base overflow-wrap break-words">{profileUser?.bio}</p>
                                                <div
                                                    className="text-sm md:text-base overflow-wrap break-words cursor-pointer"
                                                    onClick={() => navigate(`/profile/${profileUser?.username}/following`)}
                                                >
                                                    Following: {followingCount}
                                                </div>
                                                {user.id !== profileUser?._id && (
                                                    <Button variant="outline" onClick={user?.id ? handleFollowProfile : () => navigate("/auth")} className="px-4 py-2 text-sm md:text-base">{isFollowing ? "Unfollow" : "Follow"}</Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs
                                    defaultValue="reviews"
                                    className="full">
                                        <TabsListComponent>
                                            <TabsTrigger value="toRead">To Read 📚</TabsTrigger>
                                            <TabsTrigger value="reading">Reading 📖</TabsTrigger>
                                            <TabsTrigger value="reviews">Read ✅</TabsTrigger>
                                            {/* <TabsTrigger value="collections">Collections</TabsTrigger> */}
                                        </TabsListComponent>
                                        <TabsContent value="toRead">
                                        {profileToReads && profileToReads.length > 0 ? (
                                            selectedToRead ? (
                                            <>
                                                <div className="my-4">
                                                <img
                                                    src="/images/chevron-left.svg"
                                                    alt="Back"
                                                    onClick={() => navigate(`/profile/${profileUsername}`)}
                                                    style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    left: '20px',
                                                    cursor: 'pointer',
                                                    width: '20px',
                                                    height: '20px',
                                                    }}
                                                />
                                                </div>
                                                <ToRead
                                                selectedToRead={selectedToRead}
                                                refreshToReads={() => getProfileToReads(profileUser?._id)}
                                                navigatePath={`/profile/${profileUsername}`}
                                                />
                                            </>
                                            ) : (
                                            <ToReads
                                                toReads={profileToReads}
                                                refreshToReads={() => getProfileToReads(profileUser?._id)}
                                                handleToReadClick={handleToReadClick}
                                                page="profile"
                                            />
                                            )
                                        ) : (
                                            <div className="empty-message">
                                            {user.id !== profileUser?._id ? (
                                                <p>Ugh {profileUser?.username} doesn't have any books on their to read list. They need to aim higher fr.</p>
                                            ) : (
                                                <div>
                                                    <p className="pb-2">You haven't added any books to your want to read list yet 👀</p>
                                                    <p>Btw you can import your Goodreads in settings.</p>
                                                </div>
                                            )}
                                            </div>
                                        )}
                                        </TabsContent>
                                        <TabsContent value="reading">
                                            {profileReadings && profileReadings.length > 0 ? (
                                                selectedReading ? (
                                                <>
                                                    <div className="my-4">
                                                    <img
                                                        src="/images/chevron-left.svg"
                                                        alt="Back"
                                                        onClick={() => navigate(`/profile/${profileUsername}`)}
                                                        style={{
                                                        position: 'absolute',
                                                        top: '20px',
                                                        left: '20px',
                                                        cursor: 'pointer',
                                                        width: '20px',
                                                        height: '20px',
                                                        }}
                                                    />
                                                    </div>
                                                    <Reading
                                                    selectedReading={selectedReading}
                                                    refreshReadings={() => getProfileReadings(profileUser?._id)}
                                                    navigatePath={`/profile/${profileUsername}`}
                                                    />
                                                </>
                                                ) : (
                                                <Readings
                                                    readings={profileReadings}
                                                    refreshReadings={() => getProfileReadings(profileUser?._id)}
                                                    handleReadingClick={handleReadingClick}
                                                    page="profile"
                                                />
                                                )
                                            ) : (
                                                <div className="empty-message">
                                                {user.id !== profileUser?._id ? (
                                                    <p>{profileUser?.username} would be hotter if they were reading something right now. But, alas.</p>
                                                ) : (
                                                    <div>
                                                        <p className="pb-2">You would be hotter if you were reading a book right now.</p>
                                                        <p>Btw you can import your Goodreads in settings.</p>
                                                    </div>
                                                    
                                                )}
                                                </div>
                                            )}
                                        </TabsContent>
                                        <TabsContent value="collections">
                                            {profileCollections && Object.keys(profileCollections).length > 0 && (
                                                <>
                                                    {selectedCollection && !DEFAULT_COLLECTIONS.includes(selectedCollection) ? (
                                                        <>
                                                            <div className="my-4">
                                                            <img
                                                                src="/images/chevron-left.svg"
                                                                alt="Back"
                                                                onClick={() => navigate(`/profile/${profileUsername}`)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '20px',
                                                                    left: '20px',
                                                                    cursor: 'pointer',
                                                                    width: '20px',
                                                                    height: '20px'
                                                                }}
                                                            />
                                                            </div>
                                                            {user.id === profileUser._id && (
                                                                <div className="flex justify-around mb-4">
                                                                    <Button onClick={() => handleEditCollection("NON_DEFAULT")} className="px-4 py-2 text-sm md:text-base rounded-md bg-blue-500 text-white">Edit</Button>
                                                                    <Button onClick={handleDeleteCollection} className="px-4 py-2 text-sm md:text-base rounded-md bg-red-500 text-white">Delete</Button>
                                                                </div>
                                                            )}
                                                            <h2 className="text-2xl md:text-3xl mb-4">{selectedCollection}</h2>
                                                            <BookGridProfile books={profileCollections[selectedCollection]} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {user.id === profileUser?._id && (
                                                                <div className="my-4">
                                                                    <Button onClick={handleCreateCollection} className="px-4 py-2 text-sm md:text-base rounded-md bg-black text-white">Create Collection</Button>
                                                                </div>
                                                            )}
                                                            {Object.keys(profileCollections).length === 4 && (
                                                                <div className="collections-count-message text-sm italic">
                                                                    {/* <p>Make a custom book collection!</p>
                                                                    <p className="text-sm">Like collection of books you're open to lending to other OBC members.</p>
                                                                    <p>Or a collection of your favorite sci-fi books.</p> */}
                                                                </div>
                                                            )}
                                                            {Object.keys(profileCollections)
                                                                .filter(collectionName => !DEFAULT_COLLECTIONS.includes(collectionName))
                                                                .map((collectionName, i) => (
                                                                    <div key={i} className="my-8">
                                                                        <Collection
                                                                            name={collectionName}
                                                                            books={profileCollections[collectionName]}
                                                                            handleRemoveBook={handleCollectionRemoveBook}
                                                                            handleCollectionClick={handleCollectionClick}
                                                                        />
                                                                    </div>
                                                                ))}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </TabsContent>
                                        <TabsContent value="reviews">
                                            {profileReviews && profileReviews.length > 0 ? (
                                                selectedReview ? (
                                                <>
                                                    <div className="my-4">
                                                    <img
                                                        src="/images/chevron-left.svg"
                                                        alt="Back"
                                                        onClick={() => navigate(`/profile/${profileUsername}`)}
                                                        style={{
                                                        position: 'absolute',
                                                        top: '20px',
                                                        left: '20px',
                                                        cursor: 'pointer',
                                                        width: '20px',
                                                        height: '20px',
                                                        }}
                                                    />
                                                    </div>
                                                    <Review
                                                    selectedReview={selectedReview}
                                                    refreshReviews={() => getProfileReviews(profileUser?._id)}
                                                    navigatePath={`/profile/${profileUsername}`}
                                                    />
                                                </>
                                                ) : (
                                                <Reviews
                                                    reviews={profileReviews}
                                                    refreshReviews={() => getProfileReviews(profileUser?._id)}
                                                    handleReviewClick={handleReviewClick}
                                                    page="profile"
                                                />
                                                )
                                            ) : (
                                                <div className="empty-message">
                                                {user.id !== profileUser?._id ? (
                                                    <p>{profileUser?.username} doesn't have anything on their read list. I literally bet you they've read a book before though 🙄</p>
                                                ) : (
                                                    <div>
                                                        <p className="pb-2">You haven't read a book before? Really!? Add one!</p>
                                                        <p>Btw you can import your Goodreads in settings.</p>
                                                    </div>
                                                )}
                                                </div>
                                            )}
                                        </TabsContent>
                                    </Tabs>
                                    <CollectionModal
                                        collectionModalMode={collectionModalMode}
                                        collectionModalEditCollection={collectionModalEditCollection}
                                        isModalOpen={isCollectionModalOpen}
                                        setIsModalOpen={setIsCollectionModalOpen}
                                        modalStep={collectionModalStep}
                                        setModalStep={setCollectionModalStep}
                                        newCollectionName={newCollectionName}
                                        setNewCollectionName={setNewCollectionName}
                                        googleBooksSearch={googleBooksSearch}
                                        setGoogleBooksSearch={setGoogleBooksSearch}
                                        handleSearchGoogleBooks={handleSearchGoogleBooks}
                                        isDataLoading={isDataLoading}
                                        googleBooksBooks={googleBooksBooks}
                                        selections={selections}
                                        handleAddBook={handleCollectionAddBook}
                                        handleRemoveBook={handleCollectionRemoveBook}
                                        handleCreateOrUpdateCreation={handleCreateOrUpdateCreation}
                                        handleCloseCollectionModal={handleCloseCollectionModal}
                                    />
                                    <ReviewModal
                                        isModalOpen={isReviewModalOpen}
                                        setIsModalOpen={setIsReviewModalOpen}
                                        modalStep={reviewModalStep}
                                        setModalStep={setReviewModalStep}
                                        googleBooksSearch={googleBooksSearch}
                                        setGoogleBooksSearch={setGoogleBooksSearch}
                                        handleSearchGoogleBooks={handleSearchGoogleBooks}
                                        isDataLoading={isDataLoading}
                                        googleBooksBooks={googleBooksBooks}
                                        selections={selections}
                                        handleAddBook={handleReviewAddBook}
                                        handleRemoveBook={handleReviewRemoveBook}
                                        rating={rating}
                                        setRating={setRating}
                                        content={content}
                                        setContent={setContent}
                                        date={readDate}
                                        setDate={setReadDate}
                                        handlePostReview={handlePostReview}
                                        handleCloseReviewModal={handleCloseReviewModal}
                                    />
                                    <ReadingModal
                                        isModalOpen={isReadingModalOpen}
                                        setIsModalOpen={setIsReadingModalOpen}
                                        modalStep={readingModalStep}
                                        setModalStep={setReadingModalStep}
                                        googleBooksSearch={googleBooksSearch}
                                        setGoogleBooksSearch={setGoogleBooksSearch}
                                        handleSearchGoogleBooks={handleSearchGoogleBooks}
                                        isDataLoading={isDataLoading}
                                        googleBooksBooks={googleBooksBooks}
                                        selections={selections}
                                        handleAddBook={handleReadingAddBook}
                                        handleRemoveBook={handleReadingRemoveBook}
                                        content={content}
                                        setContent={setContent}
                                        date={startDate}
                                        setDate={setStartDate}
                                        handlePostReading={handlePostReading}
                                        handleCloseReviewModal={handleCloseReadingModal}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Profile;


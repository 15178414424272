"use client"

import React from "react"
import { Content, Root, TabsList, Trigger } from "@radix-ui/react-tabs"
import "../../../styles/index.css";
import { cn } from "../../utils/cn"

const Tabs = Root

const TabsListComponent = React.forwardRef(({ className, ...props }, ref) => (
    <TabsList
        ref={ref}
        className={cn(
            "font-primary inline-flex h-10 items-center justify-center",
            className
        )}
        {...props}
    />
))
TabsListComponent.displayName = TabsList.displayName

const TabsTrigger = React.forwardRef(({ className, ...props }, ref) => (
    <Trigger
        ref={ref}
        className={cn(
            "libre-caslon border-b border-b-transparent inline-flex items-center justify-center whitespace-nowrap text-xl px-3 py-0 ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:border-b-primary data-[state=active]:font-weight-600",
            className
        )}
        {...props}
    />
))
TabsTrigger.displayName = Trigger.displayName

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
    <Content
        ref={ref}
        className={cn(
            "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
            className
        )}
        {...props}
    />
))
TabsContent.displayName = Content.displayName

export { Tabs, TabsListComponent, TabsTrigger, TabsContent }
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import Reviews from "../ui/Reviews";
import Loader from "../ui/Loader";
import { Tabs, TabsListComponent, TabsTrigger, TabsContent } from "../ui/shadcn/Tabs";
import { Card } from "../ui/shadcn/Card";
import useStore from "../../store/store";

const DEFAULT_USER_RESULTS = [];
const DEFAULT_REVIEW_RESULTS = [];
const DEFAULT_BOOK_RESULTS = [];
const DEFAULT_IS_SEARCH_LOADING = false;
const DEFAULT_PFP = "/images/Default-PFP.png";

const Search = () => {
    const [userResults, setUserResults] = useState(DEFAULT_USER_RESULTS);
    const [reviewResults, setReviewResults] = useState(DEFAULT_REVIEW_RESULTS);
    const [bookResults, setBookResults] = useState(DEFAULT_BOOK_RESULTS);
    const [isSearchLoading, setIsSearchLoading] = useState(DEFAULT_IS_SEARCH_LOADING);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        init();
    }, [query]);

    const init = async () => {
        setIsSearchLoading(true);
        try {
            await searchUsers();
            await searchReviews();
            await searchBooks();
        } catch (err) {
            console.error(err);
            toast.error("Error initializing search");
        } finally {
            setIsSearchLoading(DEFAULT_IS_SEARCH_LOADING);
        }
    };

    const searchUsers = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/search_users", {
                params: {
                    query
                }
            });
            if (status === 200) {
                const users = data?.users;
                setUserResults(users);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching users");
        }
    };

    const searchReviews = async () => {
        try {
            const { data, status } = await axios.get("/api/e/v1/social/search_reviews", {
                params: {
                    query
                }
            });
            if (status === 200) {
                const reviews = data?.reviews;
                setReviewResults(reviews);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching reviews");
        }
    };

    const searchBooks = async () => {
        try {
            const { data, status } = await axios.get(`/api/e/v1/book/google_books/search`, {
                params: {
                    query
                }
            });
            if (status === 200) {
                const books = data?.books;
                console.log("books is...");
                console.log(books);
                setBookResults(books);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        }
    };

    const handleReviewClick = (reviewId) => {
        navigate(`/feed/reviews/${reviewId}`);
    };

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Header />
                <div className="flex flex-col sm:flex-row justify-center items-center full">
                    <div className="w-auto sm:w-auto px-4 sm:px-0">
                        <div className="content text-center">
                            <h2 className="text-center mx-auto pt-3">Search: {query}</h2>
                            {isUserLoading || isSearchLoading ?
                                <Loader /> :
                                <>
                                    <Tabs
                                        defaultValue="books"
                                        className="full"
                                    >
                                        <TabsListComponent>
                                            <TabsTrigger value="books">Books ({bookResults ? bookResults.length : "N/A"})</TabsTrigger>
                                            <TabsTrigger value="users">Users ({userResults ? userResults.length : "N/A"})</TabsTrigger>
                                            <TabsTrigger value="reviews">Reviews ({reviewResults ? reviewResults.length : "N/A"})</TabsTrigger>
                                        </TabsListComponent>

                                        <TabsContent value="books">
                                            {bookResults && bookResults.length > 0 ?
                                                <>
                                                    {bookResults.map((book, i) => (
                                                        <Card key={i} onClick={() => navigate(`/book/${book?.id}`)} className="flex items-center justify-start mb-4 p-8 cursor-pointer">
                                                            <img src={book?.image ? book?.image : DEFAULT_PFP} alt={book?.title} className="w-16 md:w-20 mr-6" />
                                                            <div className="text-left">
                                                                <h3>{book?.title}</h3>
                                                                {book?.authors && <small className="leading-tight">{book.authors.slice(0, 3).join(', ') + (book.authors.length > 3 ? ', ...' : '')}</small>}
                                                            </div>
                                                        </Card>
                                                    ))}
                                                </> :
                                                <p className="mb-4">No books found</p>
                                            }
                                        </TabsContent>
                                        <TabsContent value="users">
                                            {userResults && userResults.length > 0 ?
                                                <>
                                                    <p className="mb-4">{userResults.length} user{userResults.length > 1 ? "s" : ""} found</p>
                                                    {userResults.map((user, i) => (
                                                        <Card key={i} onClick={() => navigate(`/profile/${user?.username}`)} className="flex items-center justify-start mb-4 p-8 cursor-pointer">
                                                            <img src={user?.pfp ? user?.pfp : DEFAULT_PFP} alt={user?.username} className="w-16 h-16 md:w-20 md:h-20 rounded-full mr-6" />
                                                            <div className="text-left">
                                                                <h3>{user?.username}</h3>
                                                                <p>{user?.bio}</p>
                                                            </div>
                                                        </Card>
                                                    ))}
                                                </> :
                                                <p className="mb-4">No users found</p>
                                            }
                                        </TabsContent>
                                        <TabsContent value="reviews">
                                            {reviewResults && reviewResults.length > 0 ?
                                                <>
                                                    <p className="mb-4">{reviewResults.length} review{reviewResults.length > 1 ? "s" : ""} found</p>
                                                    <Reviews reviews={reviewResults} refreshReviews={searchReviews} handleReviewClick={handleReviewClick} />
                                                </> :
                                                <p className="mb-4">No reviews found</p>
                                            }
                                        </TabsContent>
                                    </Tabs>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Search;
import React from "react";
import { Button } from "./shadcn/Button";
import { Card } from "./shadcn/Card";
import { useNavigate, useLocation } from "react-router-dom";

const BookGridSearch = ({ small, type, books, selections, handleAddBook, handleRemoveBook }) => {
    const truncateLength = 25;
    const imageClass = small ? "h-32 w-32 object-cover" : "h-full w-full object-cover";
    const navigate = useNavigate();


    return (
        <>
            {books && books.length > 0 ?
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
                    {books && books.length > 0 ? books.map((book, i) => (
                        <Card key={i} >
                            <div className="flex-shrink-0 flex justify-center">
                                <img className={imageClass} src={book?.image} alt={book?.title.length > truncateLength ? `${book?.title.substring(0, truncateLength)}...` : book?.title} />
                            </div>
                            <div className="p-2 sm:p-4 text-left">
                                <h4 className="mt-1 font-semibold text-xs sm:text-sm leading-normal">{book?.title.length > truncateLength ? `${book?.title.substring(0, truncateLength)}...` : book?.title}</h4>
                                {book?.authors && <small className="leading-tight">{book.authors.slice(0, 3).join(', ') + (book.authors.length > 3 ? ', ...' : '')}</small>}
                                {selections && (
                                    <div className="mt-4 flex justify-center">
                                        {selections.some(selection => selection.isbn === book.isbn) ?
                                            (<Button className="bg-red-600 hover:bg-red-700 cursor-pointer" onClick={() => handleRemoveBook(book)}>
                                                {type === "select" ?
                                                    "Deselect" :
                                                    type === "multiple" ?
                                                        "Remove" :
                                                        ""
                                                }
                                            </Button>) :
                                            (<Button className="bg-blue-600 hover:bg-blue-700 cursor-pointer" onClick={() => handleAddBook(book)}>
                                                {type === "select" ?
                                                    "Select" :
                                                    type === "multiple" ?
                                                        "Add" :
                                                        ""
                                                }
                                            </Button>
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                        </Card>
                    )) :
                        <></>
                    }
                </div> :
                <div className="flex-col-center-center full">
                    <p>None</p>
                </div>
            }
        </>
    );
};

export default BookGridSearch;

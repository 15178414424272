import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Box, Modal, Rating } from "@mui/material";
import BookGridSearch from "./BookGridSearch";
import BookGridGoodreadsImport from "./BookGridGoodreadsImport";
import Loader from "./Loader";
import { TextAreaShadCN } from "../ui/shadcn/TextAreaShadCN";
import { Button } from "./shadcn/Button";
import { Input } from "./shadcn/Input";
import useStore from "../../store/store";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

const DEFAULT_STEP = 1;
const DEFAULT_SELECTIONS = [];
const DEFAULT_GOODREADS_URL = "";
const DEFAULT_GOODREADS_BOOKS_TO_READ = [];
const DEFAULT_GOODREADS_BOOKS_READING = [];
const DEFAULT_GOODREADS_BOOKS_READ = [];
const DEFAULT_GOODREADS_BOOKS_READ_VISIBILE = true;
const DEFAULT_GOOGLE_BOOKS_SEARCH = "";
const DEFAULT_GOOGLE_BOOKS_BOOKS = [];
const DEFAULT_USERNAME = "";
const DEFAULT_BIO = "";
const DEFAULT_PFP = null;
const DEFAULT_IS_DATA_LOADING = false;
const DEFAULT_IS_NEXT_BUTTON_SHOWING = false;
const DEFAULT_IS_POSTING = false;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const Onboard = ({ isModalOpen, setIsModalOpen, altStep }) => {
    const [step, setStep] = useState(DEFAULT_STEP);
    const [selections, setSelections] = useState(DEFAULT_SELECTIONS);
    const [goodreadsUrl, setGoodreadsUrl] = useState(DEFAULT_GOODREADS_URL);
    const [goodreadsBooksToRead, setGoodreadsBooksToRead] = useState(DEFAULT_GOODREADS_BOOKS_TO_READ);
    const [goodreadsBooksReading, setGoodreadsBooksReading] = useState(DEFAULT_GOODREADS_BOOKS_READING);
    const [goodreadsBooksRead, setGoodreadsBooksRead] = useState(DEFAULT_GOODREADS_BOOKS_READ);
    const [isGoodreadsReadBooksVisible, setIsGoodreadsReadBooksVisible] = useState(DEFAULT_GOODREADS_BOOKS_READ_VISIBILE);
    const [rating, setRating] = useState(0);
    const [content, setContent] = useState("");
    const [date, setDate] = useState(null);
    const [googleBooksSearch, setGoogleBooksSearch] = useState(DEFAULT_GOOGLE_BOOKS_SEARCH);
    const [googleBooksBooks, setGoogleBooksBooks] = useState(DEFAULT_GOOGLE_BOOKS_BOOKS);
    const [username, setUsername] = useState(DEFAULT_USERNAME);
    const [bio, setBio] = useState(DEFAULT_BIO);
    const [pfp, setPfp] = useState(DEFAULT_PFP);
    const [isDataLoading, setIsDataLoading] = useState(DEFAULT_IS_DATA_LOADING);
    const [showNextButton, setShowNextButton] = useState(DEFAULT_IS_NEXT_BUTTON_SHOWING);
    const [isPosting, setIsPosting] = useState(DEFAULT_IS_POSTING);
    const { isUserLoading, user, getUser } = useStore((state) => ({
        isUserLoading: state.isUserLoading,
        user: state.user,
        getUser: state.getUser
    }));
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        if (altStep) {
            console.log("in alt step!!")
            setStep(altStep);
        }
    }, [altStep]);

    const handleGoodreadsUrlSubmit = async () => {
        setIsDataLoading(true);
        document.getElementById("importGoodreadsButton").style.display = "none";
        try {
            let goodreadsId = "";
            const GOODREADS_ID_REGEX = /\/review\/list\/(\d+)(?=\?|$)/;
            const match = GOODREADS_ID_REGEX.exec(goodreadsUrl);
            if (match) {
                goodreadsId = match[1];
            } else {
                throw new Error("Invalid Goodreads URL");
            }
            const { data, status } = await axios.get("/api/e/v1/book/goodreads/import", {
                params: {
                    goodreads_id: goodreadsId
                }
            });
            if (status === 200) {
                const books_to_read = data?.books_to_read;
                const books_reading = data?.books_reading;
                const books_read = data?.books_read;
                setGoodreadsBooksToRead(books_to_read);
                setGoodreadsBooksReading(books_reading);
                setGoodreadsBooksRead(books_read);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error importing Goodreads books. Skip this step for now. Try again on the setting page later.");
        } finally {
            setIsDataLoading(DEFAULT_IS_DATA_LOADING);
            setShowNextButton(true);
        }
    };

    const handleAddBook = (book) => {
        setSelections([...selections, book]);
        setGoogleBooksBooks([]); // Clear the search results
    };

    const handleRemoveBook = (book) => {
        setSelections(selections.filter(selection => selection !== book));
    };

    const handleSearchGoogleBooks = async (query) => {
        setIsGoodreadsReadBooksVisible(false);
        setIsDataLoading(true);
        try {
            const { data, status } = await axios.get("/api/e/v1/book/google_books/search", {
                params: {
                    query
                }
            });
            if (status === 200) {
                const books = data?.books;
                setGoogleBooksBooks(books);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error searching books");
        } finally {
            setIsDataLoading(DEFAULT_IS_DATA_LOADING);
        }
    };

    const handlePostReview = async () => {
        setIsPosting(true);
        try {
            await processBooks();
            await handleCreateReview();
//            await getProfileReadings(profileUser._id);
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        } finally {
         await handleCloseModal();
         setIsPosting(false);
        }
    };

    const handleCreateReview = async () => {
        try {
            console.log("start date is..");
            console.log(date);
            const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                isbn: selections[0].isbn,
                rating: rating,
                content,
                read_date: date
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting reading");
        }
    };

    const handleNextStep = () => {
        if (user?.onboarded && step === 3) {
            handleCloseModal();
        } else {
            setStep(step + 1);
            window.scrollTo(0, 0);
        }
    };

    const handleButtonLabel = () => {
        return (user?.onboarded && step === 2) ? "Done" : "Next";
    };

    const processBooks = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/book/process_books", {
                selections,
                collection_name: "Reading"
            });
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error processing books");
        }
    };

    const handleChange = (setter, limit) => (e) => {
        if (e.target.value.length <= limit) {
            setter(e.target.value);
        }
    };

    const onboardUser = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/auth/onboard_user");
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error onboarding user");
        }
    };

    const updateUser = async () => {
        try {
            if (username.trim() === "") {
                toast.error("Username cannot be empty");
                return false;
            }
            if (username.includes(" ")) {
                toast.error("Username cannot contain spaces");
                return false;
            }
            const { data } = await axios.get("/api/e/v1/auth/check_username", {
                params: {
                    username
                }
            });
            if (data.exists) {
                toast.error("Username already exists");
                setUsername(user?.username || ''); 
                return false;
            }
            const formData = new FormData();
            formData.append("username", username);
            formData.append("bio", bio);
            formData.append("pfp", pfp);
            const { data: update_user_data, status } = await axios.post("/api/e/v1/auth/update_user", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (status !== 200) {
                const err = update_user_data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error updating user");
        }
    };

    const handleSubmitOnboard = async () => {
        try {        
            await processBooks();
             // If updateUser throws an error or returns a status indicating failure, it should stop further execution
            const updateResult = await updateUser(); 
            // Check if updateUser succeeded before proceeding
            if (updateResult === false) {
                return; // Prevent further execution if username exists
            }
            await onboardUser();
            setStep(3); // Proceed to next step only if onboarding is successful
            
        } catch (err) {
            console.error(err);
            toast.error("Error onboarding");
        } 
        // finally {
        //     navigate(`/feed`);
        // }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setStep(DEFAULT_STEP);
        setGoogleBooksSearch(DEFAULT_GOOGLE_BOOKS_SEARCH);
        setGoogleBooksBooks(DEFAULT_GOOGLE_BOOKS_BOOKS);
        setSelections(DEFAULT_SELECTIONS);
        navigate(`/feed`);
    };

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                if (user?.onboarded) {
                    handleCloseModal();
                } else {
                    toast.error("Please complete the onboarding process before closing.");
                }
            }}
        >
            <Box sx={style} style={{ overflowY: "auto", maxHeight: "75vh" }}>
                <div className="flex flex-col items-center gap-4 w-full text-center">

                    {isUserLoading ?
                        <Loader /> :
                        <>
                        {step === 1 && (
                                <>
                                    <div className="text-left">
                                    <h2 className="text-2xl md:text-3xl mb-4" >Create your profile</h2>
                                    {/* <p>Secure your go to username before anyone else.</p> */}
                                    </div>
                                    <div className="flex flex-col items-center justify-center gap-4 w-full">
                                        <label className="w-3/4">
                                            Username*:
                                            <Input className="w-full" type="text" placeholder="Liz" value={username} onChange={handleChange(setUsername, 15)} required />
                                            <p>{username ? `${username.length}/15` : '0/15'}</p>
                                        </label>
                                        <label className="w-3/4 mt-4 md:mt-0">
                                            Bio:
                                            <TextAreaShadCN className="w-full" type="text" placeholder="I love open book club" value={bio} onChange={handleChange(setBio, 250)} />
                                            <p>{bio.length}/250</p>
                                        </label>
                                        <div className="flex justify-center items-center">
                                            <label className="w-3/4 mt-4 md:mt-0">
                                                Profile Picture:
                                                <input type="file" accept="image/*" className="w-full" onChange={e => setPfp(e.target.files[0])} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center gap-4 w-full">
                                    {username.length > 0 ? (
                                        <>
                                            <Button onClick={handleSubmitOnboard}>Next</Button>
                                        </>
                                    ) : (
                                        <p>Please enter a username</p>
                                    )}
                                    </div>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <div className="flex flex-col items-center justify-center gap-4 w-full">
                                        <h2 className="text-2xl md:text-3xl mb-4" >Import books from goodreads</h2>
                                        <p style={{ overflowWrap: "break-word" }}>To import your Goodreads books, go to Goodreads.com &gt; My Books &gt; and copy and paste the URL below</p>
                                        <p style={{ overflowWrap: "break-word" }}>Or on the Goodreads app go to My Books &gt; Read &gt; Share &gt; Copy link </p>
                                        <p style={{ overflowWrap: "break-word" }}>Note: your Goodreads profile and book lists must be public for import to work!</p>
                                        <Input className="w-1/2" type="text" placeholder="https://www.goodreads.com/review/list/[GOODREADS_ID]" onChange={(e) => setGoodreadsUrl(e.target.value)} />
                                        <Button id="importGoodreadsButton" className="w-1/2 md:w-auto" onClick={handleGoodreadsUrlSubmit}>Import Goodreads</Button>
                                    </div>
                                    {isDataLoading ?
                                        <Loader /> :
                                        showNextButton &&
                                        <div className="flex justify-center gap-4 w-full">
                                            <Button variant="outline" onClick={() => handleCloseModal()}>Done!</Button>
                                        </div>
                                    }
                                    {/* <div className="flex justify-center gap-4 w-full">
                                        <Button variant="outline" onClick={() => setStep(3)}>Skip</Button>
                                    </div> */}
                                </>
                            )}
                            {step === 3 && (
                                <>
                                <div className="text-left">
                                    <h2 className="text-2xl md:text-3xl mb-4" >What's the last book you read?</h2>
                                    <p>It was probably for an open book club meeting.</p>
                                </div>
                                <div className="flex justify-center gap-4 w-full">
                                    <form className="flex justify-between items-center" onSubmit={(e) => { e.preventDefault(); handleSearchGoogleBooks(googleBooksSearch); }}>
                                        <label>
                                            <Input type="text" placeholder="Search title, author" value={googleBooksSearch} onChange={(e) => setGoogleBooksSearch(e.target.value)} />
                                        </label>
                                        <div className="px-4">
                                            <Button type="submit">Search</Button>
                                        </div>
                                    </form>
                                </div>
                                {isDataLoading ?
                                    <Loader /> :
                                    googleBooksBooks.length > 0 &&
                                    <BookGridSearch small type="select" books={googleBooksBooks} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                                }
                                {selections.length > 0 &&
                                    <>
                                        <h3 className="text-center text-2xl">Selected Book</h3>
                                        <BookGridSearch small type="select" books={selections} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                                    </>
                                }
                                {selections.length === 1 && <Button onClick={() => setStep(4)}>Next</Button>}
                            </>
                            )}
                            {step === 4 && (
                                <>
                                <div className="text-left">
                                    <h2 className="text-2xl md:text-3xl mb-4" >What would you rate it?</h2>
                                    <p>There are no half stars you have to be decisive.</p>
                                </div>
                                    <Rating
                                        value={rating}
                                        onChange={(e, rating) => {
                                            setRating(rating);
                                        }}
                                    />
                                    <div className="flex justify-center gap-4 w-full">
                                        <Button onClick={() => setStep(3)}>Previous</Button>
                                        <Button onClick={() => setStep(5)}>Next</Button>
                                    </div>
                                </>
                            )}
                            {step === 5 && (
                                <>
                                <div className="text-left">
                                    <h2 className="text-2xl md:text-3xl mb-4" >What did you think of it?</h2>
                                    <p>Don’t overthink it. You’re a reader, not a writer. Hot takes encouraged.</p>
                                </div>
                                    <TextAreaShadCN value={content} onChange={handleChange(setContent, 250)} />
                                    <p>{content?.length}/250</p>
                                    <div className="flex justify-center gap-4 w-full">
                                        <Button onClick={() => setStep(4)}>Previous</Button>
                                        <Button onClick={() => setStep(6)}>Next</Button>
                                    </div>
                                </>
                            )}
                            {step === 6 && (
                                <>
                                <div className="text-left">
                                    <h2 className="text-2xl md:text-3xl mb-4" >When did you finish it?</h2>
                                    <p>It's ok if you don't know the exact date.</p>
                                </div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disableFuture={true}
                                            value={date}
                                            onChange={(date) => setDate(date)}
                                        />
                                    </LocalizationProvider>
                                    <div className="flex justify-center gap-4 w-full">
                                        <Button onClick={() => setStep(5)}>Previous</Button>
                                        {date && <Button onClick={() => handlePostReview()} disabled={isPosting}>Done!</Button>}
                                    </div>
                                </>
                            )}
                            {/* {step === 4 && (
                                <>
                                    <h2 className="text-xl md:text-xl lg:text-2xl">Welcome to OBC.</h2>
                                    <h3>Review your first post!</h3>
                                    <p>Book: {selections[0]?.title.length > 25 ? `${selections[0].title.substring(0, 25)}...` : selections[0].title}</p>
                                    <p>Comment: {content}</p>
                                    <p>Started on: {dayjs(date).format('MM/DD/YYYY')}</p>
                                    <div className="flex justify-center gap-4 w-full">
                                        <Button onClick={() => setStep(3)}>Previous</Button>
                                        <Button onClick={() => handlePostReading()}>Post</Button>
                                    </div>
                                </>
                            )} */}
                            
                        </>
                    }
                </div>
            </Box>
        </Modal>
    );
};

export default Onboard;


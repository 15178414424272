import React from "react";

const PhoneNumberInput = ({ phoneNumber, setPhoneNumber }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            setPhoneNumber(value);
        }
    };

    return (
        <div className="flex-col-center-center gap full">
            <input style={{ backgroundColor: 'white',
                outlineColor: '#BD270B',
                outlineStyle: 'solid',
                outlineWidth: '1px',
                border: '1px solid #BD270B',
                borderRadius: '0px',
                boxShadow: '0 0 0 1px #BD270B'}} 
                id="phone-number"
                type="tel"
                value={phoneNumber}
                onChange={handleChange}
                placeholder="Phone number"
                className="w-[50%] p-2 my-1 text-sm md:text-base"
            />
        </div>
    );
};

export default PhoneNumberInput;
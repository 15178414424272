export const formatCollectionName = (name) => {
    const formattedName = name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
    return formattedName;
};

export const formatUTCDate = (datetime) => {
    const dateObject = new Date(datetime);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObject);
    return formattedDate;
};

export const formatUTCDatetime = (datetime) => {
    const dateObject = new Date(datetime);
    const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObject);
    return formattedDate;
};
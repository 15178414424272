import React from "react";
import { Button } from "./shadcn/Button";
import { Card } from "../ui/shadcn/Card";
import { formatCollectionName } from "../utils/formatters";
import { useNavigate, useLocation } from "react-router-dom";

const Collection = ({ name, books, selections, handleAddBook, handleRemoveBook, handleCollectionClick }) => {

    const booksToShow = books.slice(0, 4);
    const navigate = useNavigate();
    
    return (
        <div className="cursor-pointer mx-auto mb-4">
            <div className="flex justify-between items-center">
                <h2 className="text-lg sm:text-xl mb-4">{name}</h2>
                <span
                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={() => handleCollectionClick(formatCollectionName(name))}
                >
                    View All
                </span>
            </div>
            <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-4 justify-center">
                {booksToShow.length > 0 ?
                    booksToShow.map((book, i) => (
                        <Card key={i} onClick={() => navigate(`/book/${book?.id}`)} className="flex-none w-full sm:w-40">
                            <div className="flex-shrink-0">
                                <img className="h-full w-full object-cover" src={book?.image} alt={book?.title.length > 25 ? `${book?.title.substring(0, 25)}...` : book?.title} />
                            </div>
                            <div className="p-2 sm:p-4 text-left">
                                <h4 className="mt-1 font-semibold text-xs sm:text-sm leading-normal">{book?.title.length > 25 ? `${book?.title.substring(0, 25)}...` : book?.title}</h4>
                                {book?.authors && <small className="leading-tight">{book.authors.slice(0, 3).join(', ') + (book.authors.length > 3 ? ', ...' : '')}</small>}
                            </div>
                            {selections &&
                                <div className="mt-2 sm:mt-4 flex justify-center">
                                    {selections.some(selection => selection.isbn === book.isbn) ? (
                                        <Button className="text-red-600 hover:text-red-500" onClick={() => handleRemoveBook(book)}>Remove</Button>
                                    ) : (
                                        <Button className="text-indigo-600 hover:text-indigo-500" onClick={() => handleAddBook(book)}>Add</Button>
                                    )} 
                                </div>
                            }
                        </Card>
                    )) :
                    <div className="flex-col-left-center full">
                        <p>None</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default Collection;

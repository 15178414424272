import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Rating from '@mui/material/Rating';
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import IosShareIcon from '@mui/icons-material/IosShare';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Button } from "../ui/shadcn/Button";
import { TextAreaShadCN } from "../ui/shadcn/TextAreaShadCN";
import { Card } from "../ui/shadcn/Card";
import Loader from "../ui/Loader";
import { formatUTCDate, formatUTCDatetime } from "../utils/formatters";
import useStore from "../../store/store";
import ReviewModal from "../ui/ReviewModal";


const DEFAULT_ANCHOR_EL_READING_MENU = null;
const DEFAULT_ANCHOR_EL_COMMENT_MENU = null;
const DEFAULT_ANCHOR_EL_LIKE_MENU = null;
const DEFAULT_COMMENTS = [];
const DEFAULT_NEW_COMMENT = "";
const DEFAULT_IS_LOADING = false;
const DEFAULT_REVIEW_MODAL_STEP = 2;
const DEFAULT_IS_REVIEW_MODAL_OPEN = false;
const DEFAULT_RATING = 0;
const DEFAULT_CONTENT = "";
const DEFAULT_READ_DATE = null;

const DEFAULT_PFP = "/images/Default-PFP.png";

const Reading = ({ selectedReading, refreshReadings, navigatePath }) => {
    const [anchorElReadingMenu, setAnchorElReadingMenu] = useState(DEFAULT_ANCHOR_EL_READING_MENU);
    const [anchorElCommentMenu, setAnchorElCommentMenu] = useState(DEFAULT_ANCHOR_EL_COMMENT_MENU);
    const [anchorElLikeMenu, setAnchorElLikeMenu] = useState(DEFAULT_ANCHOR_EL_LIKE_MENU);
    const [comments, setComments] = useState(DEFAULT_COMMENTS);
    const [likes, setLikes] = useState([]);
    const [newComment, setNewComment] = useState(DEFAULT_NEW_COMMENT);
    const [isLoading, setIsLoading] = useState(DEFAULT_IS_LOADING);
    const [reviewModalStep, setReviewModalStep] = useState(DEFAULT_REVIEW_MODAL_STEP);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(DEFAULT_IS_REVIEW_MODAL_OPEN);
    const [rating, setRating] = useState(DEFAULT_RATING);
    const [content, setContent] = useState(DEFAULT_CONTENT);
    const [readDate, setReadDate] = useState(DEFAULT_READ_DATE);
    const { user } = useStore((state) => ({ user: state.user }));
    const navigate = useNavigate();

    useEffect(() => {
        getComments();
    }, [selectedReading]);

    const handleClickReadingMenu = (event) => {
        setAnchorElReadingMenu(event.currentTarget);
    };

    const handleCloseReadingMenu = () => {
        setAnchorElReadingMenu(null);
    };

    const handleClickCommentMenu = (event) => {
        setAnchorElCommentMenu(event.currentTarget);
    };

    const handleCloseCommentMenu = () => {
        setAnchorElCommentMenu(null);
    };

    const handleDeleteReading = async () => {
        try {
            const { data, status } = await axios.delete(`/api/e/v1/social/delete_reading/${selectedReading?._id}`);
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error deleting reading");
        } finally {
            await refreshReadings();
            navigate(navigatePath);
        }
    };

    const handleToggleLikeReading = async () => {
        try {
            const { data, status } = await axios.post(`/api/e/v1/social/toggle_like_reading`, {
                reading_id: selectedReading?._id
            });
            if (status === 200) {
                const message = data?.message;
                if (message === "liked") {
                    handleLikedReadingNotification();
                }
                refreshReadings();
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error liking reading");
        } finally {
            refreshReadings();
        }
    };

    const handleLikedReadingNotification = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/new_like_sms", {
                post_id: selectedReading?._id,
                post_type: "reading",
                profile_id: selectedReading?.user?._id
            });
        } catch (err) {
            console.error(err);
        }
    }

    const getComments = async () => {
        setIsLoading(true);
        try {
            const { data, status } = await axios.get("/api/e/v1/social/get_reading_comments", {
                params: {
                    reading_id: selectedReading?._id
                }
            });
            if (status === 200) {
                const comments = data?.comments;
                setComments(comments);
                refreshReadings();
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching comments");
        } finally {
            setIsLoading(DEFAULT_IS_LOADING);
        }
    };

    const handleChange = (setter, limit) => (e) => {
        if (e.target.value.length <= limit) {
            setter(e.target.value);
        }
    };

    const handleCreateComment = async (e) => {
        try {
            if (newComment.trim() === "") {
                toast.error("Comment cannot be empty");
                return;
            }
            const { data, status } = await axios.post("/api/e/v1/social/create_comment", {
                content: newComment,
                target_id: selectedReading?._id,
                target_type: "reading"
            });
            if (status == 200) {
                handleCreateCommentNotification();
                refreshReadings();
            }
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error creating comment");
        } finally {
            await refreshReadings();
            setNewComment(DEFAULT_NEW_COMMENT);
        }
    };

    const handleCreateCommentNotification = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/new_comment_sms", {
                post_id: selectedReading?._id,
                post_type: "reading",
                profile_id: selectedReading?.user?._id
            });
        } catch (err) {
            console.error(err);
        }
    }
    

    const handleDeleteComment = async (commentId) => {
        try {
            const { data, status } = await axios.delete(`/api/e/v1/social/delete_comment/${commentId}`);
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error deleting comment");
        } finally {
            await handleCloseCommentMenu();
            await refreshReadings();
            setNewComment(DEFAULT_NEW_COMMENT);
        }
    };

    const handleShare = async () => {
        try {
            const url = `${window.location.origin}`;
            await navigator.clipboard.writeText(url);
            toast.success("Copied link! Share to socials coming soon. Take a screenshot for now!");
        } catch (err) {
            console.error('Failed to copy: ', err);
            toast.error("Failed to copy the link. Please try again.");
        }
        toast.success("Copied link! Share to socials coming soon. Take a screenshot for now!")
    }

    const handleMoveToRead = async () => {
        setIsReviewModalOpen(true);
    }

    const handlePostReview = async () => {
        try {
            await handleCreateReview();
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        } finally {
            await handleCloseReviewModal();
        }
    };

    const handleCloseReviewModal = async () => {
        try {
            setIsReviewModalOpen(DEFAULT_IS_REVIEW_MODAL_OPEN);
            setReviewModalStep(DEFAULT_REVIEW_MODAL_STEP);
            setRating(DEFAULT_RATING);
            setContent(DEFAULT_CONTENT);
            setReadDate(DEFAULT_READ_DATE);
            navigate(`/profile/${user.username}`);
        } catch (err) {
            console.error(err);
            toast.error("Error closing review modal");
        }
    };

    const handleCreateReview = async () => {
        try {
            const { data, status } = await axios.post("/api/e/v1/social/create_review", {
                isbn: selectedReading.book.isbn,
                rating: rating,
                content: content, // New review content
                reading_content: selectedReading.content, //Existing content from reading post
                to_read_content: selectedReading.to_read_content, //Existing content from want to read post
                read_date: readDate
            });
            if (status == 200) {
                const { data, status } = await axios.post("/api/e/v1/social/edit_status", {
                    id: selectedReading?._id,
                    type: "reading",
                    status: "hidden"
                });
                await refreshReadings();
            }
            if (status !== 200) {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error posting review");
        }
    };

    const handleClickLikeMenu = async (event) => {
        setAnchorElLikeMenu(event.currentTarget);
        setIsLoading(true); // Set loading to true when fetching data
        try {
            // Make a GET request to fetch the likes for the specific post
            const { data, status } = await axios.get("/api/e/v1/social/get_likes", {
                params: {
                    post_id: selectedReading?._id,
                    post_type: "reading"
                }
            });
            if (status === 200) {
                const likes = data?.likes;
                setLikes(likes);
            } else {
                const err = data?.err;
                throw new Error({ err, status });
            }
        } catch (err) {
            console.error(err);
            toast.error("Error fetching likes for the post");
            return []; // Return an empty array in case of an error
        }finally {
            setIsLoading(false); // Set loading to false after data is fetched
        }
    };

    const handleCloseLikeMenu = () => {
        setAnchorElLikeMenu(null);
    };

    return (
        <Card className="w-full cursor-pointer overflow-hidden">
            <div className="flex flex-col items-center gap-4 p-4 bg-white rounded-lg shadow">
                <div className="flex items-center w-full">
                    <div className="flex-row-left-center gap-2 full">
                        <div className="flex-shrink-0">
                            <img className="w-16 h-16 rounded-full object-cover" src={selectedReading?.user?.pfp ? selectedReading?.user?.pfp : DEFAULT_PFP} alt={selectedReading?.user?.username} onClick={() => navigate(`/profile/${selectedReading?.user?.username}`)} />
                        </div>
                        <div className="text-left">
                            <h1 className="text-xl"><a href={`/profile/${selectedReading?.user?.username}`} className="text-xl">{selectedReading?.user?.username}</a> is reading <i>{selectedReading?.book?.title}</i></h1>
                            <span className="text-xs text-gray-500">{formatUTCDate(selectedReading?.published_date)}</span>
                        </div>
                    </div>
                    <div className="flex-row-right-center">
                        {user.id === selectedReading?.user?._id &&
                            <>
                                <IconButton onClick={handleClickReadingMenu}>
                                    <img src="/images/edit.svg" alt="edit icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElReadingMenu}
                                    keepMounted
                                    open={Boolean(anchorElReadingMenu)}
                                    onClose={handleCloseReadingMenu}
                                >
                                    <MenuItem onClick={handleMoveToRead}>Mark as Read</MenuItem>
                                    <MenuItem onClick={handleDeleteReading}>Delete Reading</MenuItem>
                                </Menu>
                            </>
                        }
                    </div>
                </div>
                <img className="object-cover h-auto w-3/4 max-w-full sm:max-w-xs" src={selectedReading?.book?.image} alt={selectedReading?.book?.title} onClick={() => navigate(`/book/${selectedReading?.book?.id}`)} />
                <div className="flex-col-left-center full mt-4">
                    <p className="text-sm text-gray-600 text-left overflow-wrap break-words"><u>Reading notes:</u> {selectedReading?.content}</p>
                    {selectedReading?.to_read_content?.trim() && (
                        <p className="text-sm text-gray-600 text-left overflow-wrap break-words">
                            <u>To read notes:</u> {selectedReading.to_read_content}
                        </p>
                    )}
                </div>
                <div className="flex-row-right-center full mt-4">
                    <IconButton>
                        <img src="/images/message-square-02.svg" alt="profile icon" className="h-6 w-6 sm:h-8 sm:w-8 lg:h-6 lg:w-6" />
                    </IconButton>
                    <span>{selectedReading.comments_count}</span>
                    <IconButton onClick={user?.id ? (event) => handleToggleLikeReading(event, selectedReading._id, selectedReading.user._id) : () => navigate("/auth")}>
                        {(() => {
                            const hasLiked = selectedReading?.liked_by_user;
                            const likeCount = selectedReading?.likes;
                            
                            if (hasLiked && likeCount >= 2) {
                                return <span className="text-2xl">❤️‍🔥</span>;  // Liked with many likes
                            } else if (hasLiked && likeCount === 1) {
                                return <span className="text-2xl">❤️</span>;  // Liked with few likes
                            } else {
                                return <span className="text-2xl">🤍</span>;  // Unliked with few likes
                            }
                        })()}
                    </IconButton>
                    <span onClick={handleClickLikeMenu}>{selectedReading?.likes}</span>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElLikeMenu}
                        keepMounted
                        open={Boolean(anchorElLikeMenu)}
                        onClose={handleCloseLikeMenu}
                    >
                        <MenuItem>Liked by:</MenuItem>
                        {likes.map((like, i) => (
                            <MenuItem key={i}>
                                <a href={`/profile/${like?.user?.username}`}>
                                    {like?.user?.username ? like?.user?.username : "Unknown User"}
                                </a>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
                <div className="mt-4 text-left w-full">
                    <h4 className="font-semibold text-lg leading-tight truncate">Comments:</h4>
                    {isLoading ?
                        <Loader /> :
                        <>
                            {comments.map((comment, i) => (
                                <div key={i} className="border-t-2 border-gray-200 pt-2 mb-4 flex justify-between">
                                    <div>
                                        <p className="text-sm mb-2 overflow-wrap break-words">
                                            <a href={`/profile/${comment?.user?.username}`}>{comment?.user?.username ? comment?.user?.username : "N/A"}:</a> {comment?.content}
                                        </p>
                                        <p className="text-xs text-gray-500 mb-2">
                                            {comment?.published_date ? formatUTCDatetime(comment?.published_date) : "N/A"}
                                        </p>
                                    </div>
                                    {user.id === comment?.user?._id &&
                                        <div>
                                            <IconButton onClick={handleClickCommentMenu}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorElCommentMenu}
                                                keepMounted
                                                open={Boolean(anchorElCommentMenu)}
                                                onClose={handleCloseCommentMenu}
                                            >
                                                <MenuItem onClick={() => handleDeleteComment(comment._id)}>Delete Comment</MenuItem>
                                            </Menu>
                                        </div>
                                    }
                                </div>
                            ))}
                        </>
                    }
                    <div className="mb-4">
                        <TextAreaShadCN className="w-full" type="text" value={newComment} onChange={handleChange(setNewComment, 250)} />
                        <p>{newComment.length}/250</p>
                    </div>
                    <div className="mt-4 text-right">
                        <Button onClick={user?.id ? handleCreateComment : () => navigate("/auth")}>Submit Comment</Button>
                    </div>
                </div>
            </div>
            <ReviewModal
                    isModalOpen={isReviewModalOpen}
                    setIsModalOpen={setIsReviewModalOpen}
                    modalStep={reviewModalStep} //Skip the searching + selecting 
                    setModalStep={setReviewModalStep}
                    googleBooksSearch={() => {}} // Dummy function
                    setGoogleBooksSearch={() => {}} // Dummy function
                    handleSearchGoogleBooks={() => {}} // Dummy function
                    isDataLoading={() => {}} // Dummy function
                    googleBooksBooks={() => {}} // Dummy function
                    selections={selectedReading} // This might not work! Bu trying to set selection as current book detail
                    handleAddBook={() => {}} // Dummy function
                    handleRemoveBook={() => {}} // Dummy function
                    rating={rating}
                    setRating={setRating}
                    content={content}
                    setContent={setContent}
                    date={readDate}
                    setDate={setReadDate}
                    handlePostReview={handlePostReview}
                    handleCloseReviewModal={handleCloseReviewModal}
                />
        </Card>
        
    );
};

export default Reading;

import React from "react";

const VerificationCode = ({ verificationCode, setVerificationCode }) => {
    const handleKeyDown = (e, i) => {
        if (e.key === "Backspace") {
            const newCode = [...verificationCode];
            if (e.target.value !== "") {
                newCode[i] = "";
            }
            setVerificationCode(newCode);
            if (e.target.value === "" && e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleChange = (e, i) => {
        const newCode = [...verificationCode];
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            if (value.trim().length > 1) {
                e.clipboardData = {
                    getData: () => value.trim(),
                };
                handlePaste(e);
                return;
            }
            newCode[i] = value;
            setVerificationCode(newCode);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text").split("");
        const regex = /^[0-9]*$/;
        if (paste.length === verificationCode.length && regex.test(paste.join(''))) {
            setVerificationCode(paste);
            e.target.blur();
        }
    };

    return (
        <div className="flex-col-center-center gap full">
            <div className="flex justify-between mb-1">
                {verificationCode.map((n, i) => {
                    return (
                        <input style={{
                            backgroundColor: 'white',
                            outlineColor: '#BD270B',
                            outlineStyle: 'solid',
                            outlineWidth: '1px',
                            border: '1px solid #BD270B',
                            borderRadius: '0px',
                            boxShadow: '0 0 0 1px #BD270B'}} 
                            key={i}
                            type="tel"
                            id="verification-code"
                            maxLength="1"
                            value={n}
                            onChange={(e) => handleChange(e, i)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            onPaste={handlePaste}
                            className="w-[50%] p-2 m-1 text-center text-sm md:text-base border-2"
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default VerificationCode;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import { Button } from "../ui/shadcn/Button";
import { Input } from "../ui/shadcn/Input";
import BookGridSearch from "./BookGridSearch";
import Loader from "../ui/Loader";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

const CollectionModal = ({
    collectionModalMode,
    collectionModalEditCollection,
    isModalOpen,
    setIsModalOpen,
    modalStep,
    setModalStep,
    newCollectionName,
    setNewCollectionName,
    googleBooksSearch,
    setGoogleBooksSearch,
    handleSearchGoogleBooks,
    isDataLoading,
    googleBooksBooks,
    selections,
    handleAddBook,
    handleRemoveBook,
    handleCreateOrUpdateCreation,
    handleCloseCollectionModal
}) => {
    const [isCollectionNameUnique, setIsCollectionNameUnique] = useState(true);

    useEffect(() => {
        if (collectionModalMode === "create") {
            axios.get('/api/e/v1/collection/check_collection_name', {
                params: {
                    collection_name: newCollectionName
                }
            }).then(res => {
                setIsCollectionNameUnique(res.data.isUnique);
            });
        }
    }, [collectionModalMode, newCollectionName]);

    useEffect(() => {
        if (collectionModalMode === "edit" && collectionModalEditCollection === "DEFAULT") {
            setModalStep(2);
        }
    }, [collectionModalMode, collectionModalEditCollection]);

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseCollectionModal}
        >
            <Box sx={style} style={{ overflowY: "auto", maxHeight: "75vh" }}>
                <div className="flex flex-col items-center gap-4 w-full">
                    {modalStep === 1 && (
                        <>
                            {collectionModalMode === "create" ?
                                <h3 className="text-center">What would you like to name your new collection?</h3> :
                                <h3 className="text-center">What would you like to rename your collection to?</h3>
                            }
                            <Input type="text" value={newCollectionName} onChange={(e) => setNewCollectionName(e.target.value)} />
                            {newCollectionName.length === 0 && <p>You must enter a collection name.</p>}
                            {collectionModalMode === "create" && !isCollectionNameUnique && <p>Collection name already exists. Please choose a different name.</p>}
                            <div className="flex justify-center gap-4 w-full">
                                {newCollectionName.length > 0 && (collectionModalMode === "edit" || (collectionModalMode === "create" && isCollectionNameUnique)) && <Button onClick={() => setModalStep(2)}>Next</Button>}
                            </div>
                        </>
                    )}
                    {modalStep === 2 && (
                        <>
                            <div className="flex justify-center gap-4 w-full">
                                <form className="flex justify-between items-center" onSubmit={(e) => { e.preventDefault(); handleSearchGoogleBooks(googleBooksSearch); }}>
                                    <label>
                                        Search Books
                                        <Input type="text" placeholder="Search Books" value={googleBooksSearch} onChange={(e) => setGoogleBooksSearch(e.target.value)} />
                                    </label>
                                    <div className="px-4">
                                        <Button type="submit">Search</Button>
                                    </div>
                                </form>
                            </div>
                            {isDataLoading ?
                                <Loader /> :
                                googleBooksBooks.length > 0 &&
                                <BookGridSearch small type="multiple" books={googleBooksBooks} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                            }
                            {selections.length > 0 &&
                                <>
                                    <h3 className="text-center text-2xl">Selected Books</h3>
                                    <BookGridSearch small type="multiple" books={selections} selections={selections} handleAddBook={handleAddBook} handleRemoveBook={handleRemoveBook} />
                                </>
                            }
                            {selections.length === 0 && <p>Please select at least one book.</p>}
                            <div className="flex justify-center gap-4 w-full">
                                {collectionModalEditCollection !== "DEFAULT" && <Button onClick={() => setModalStep(1)}>Previous</Button>}
                                {selections.length > 0 && <Button onClick={() => setModalStep(3)}>Next</Button>}
                            </div>
                        </>
                    )}
                    {modalStep === 3 && (
                        <>
                            <h3 className="text-center text-2xl">Review your inputs</h3>
                            <p>Collection Name: {newCollectionName}</p>
                            <p>Books:</p>
                            {selections.map((book, index) => <p key={index}>{book?.title.length > 25 ? `${book?.title.substring(0, 25)}...` : book?.title} {book?.authors && <span className="leading-tight">by {book.authors.slice(0, 3).join(', ') + (book.authors.length > 3 ? ', ...' : '')}</span>}</p>)}
                            <div className="flex justify-center gap-4 w-full">
                                <Button onClick={() => setModalStep(2)}>Previous</Button>
                                <Button onClick={() => {handleCreateOrUpdateCreation()}}>Post Collection</Button>
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    );
}

export default CollectionModal;
